import { defineStore } from 'pinia'

export const useCustomerInfo = defineStore('customerInfo', {
  state: () => ({
    blackProgressLoading: false // 黑名單 Loading
  }),
  getters: {
  },
  actions: {
  }
})
